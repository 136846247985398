/*fonts*/
@font-face {
    font-family: 'Suisse';
    src: url('fonts/suisseintl-medium-webfont.woff2') format('woff2'),
         url('fonts/suisseintl-medium-webfont.woff') format('woff'),
         url('fonts/suisseintl-medium-webfont.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Suisse';
    src: url('fonts/suisseintl-regular-webfont.woff2') format('woff2'),
         url('fonts/suisseintl-regular-webfont.woff') format('woff'),
         url('fonts/suisseintl-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Suisse';
    src: url('fonts/suisseintl-regularitalic-webfont.woff2') format('woff2'),
         url('fonts/suisseintl-regularitalic-webfont.woff') format('woff'),
         url('fonts/suisseintl-regularitalic-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Suisse';
    src: url('fonts/suisseintl-semibold-webfont.woff2') format('woff2'),
         url('fonts/suisseintl-semibold-webfont.woff') format('woff'),
         url('fonts/suisseintl-semibold-webfont.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}


@font-face {
    font-family: 'Pp_neue';
    src: url('fonts/ppneuemachina-300-webfont.woff2') format('woff2'),
         url('fonts/ppneuemachina-300-webfont.woff') format('woff'),
         url('fonts/ppneuemachina-300-webfont.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Pp_neue';
    src: url('fonts/ppneuemachina-400-webfont.woff2') format('woff2'),
         url('fonts/ppneuemachina-400-webfont.woff') format('woff'),
         url('fonts/ppneuemachina-400-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Pp_neue';
    src: url('fonts/ppneuemachina-900-webfont.woff2') format('woff2'),
         url('fonts/ppneuemachina-900-webfont.woff') format('woff'),
         url('fonts/ppneuemachina-900-webfont.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

.err {
    color: red !important;
}

/*reset light*/
html, body {
    margin: 0;
    padding: 0;
}
label, input, button, a {
    -webkit-tap-highlight-color: transparent;
}
body {
    font-family: 'Suisse', 'sans-serif', 'arial', 'helvetica';
    -webkit-font-smoothing: antialiased; 
    --c_h: rgba(88, 136, 103, 0.8);
    --c2: #E5E5E5;
    --c3: #566494;
    --c4: #565E7C;
}
svg path, svg rect, svg circle {
    -webkit-transition: 0.3s;
       -moz-transition: 0.3s;
        -ms-transition: 0.3s;
         -o-transition: 0.3s;
            transition: 0.3s;
}
input, textarea, select, button {
    font-family: 'Suisse', 'sans-serif', 'arial', 'helvetica';
}
input:not([type=checkbox]):not([type=radio]),
textarea, 
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
a:hover {
    text-decoration: none;
}
img, a, input, textarea, select, button {
    -webkit-border-radius: 0;
            border-radius: 0;
    outline-style: none;
}
button::-moz-focus-inner {
  border: 0;
}
    
input {
    box-shadow: none;
    -webkit-box-shadow: none;
}
.container_main {
    min-width: 320px;
    background: #27423E url("../img/volna_main.svg") no-repeat center top;
    padding-top: 226px;
    overflow: hidden;
    width: 100%;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}
.clr {
    clear: both;
}
.tac {
    text-align: center;
}
.align_center {
    text-align: center;
}
.wmain {
    width: 1260px;
    margin: 0 auto;
}

.overflow {
    overflow: hidden;
}

.plr {
    padding-left: 30px;
    padding-right: 30px;
}

.decor_ico {
    display: block;
    position: absolute;
}

    .decor_ico img {
        display: block;
    }

.header {
    position: relative;
    overflow: hidden;
    z-index: 10;
}

    .header .wmain {
        position: relative;
        z-index: 40;
        display: flex;
        padding-right: 80px;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
    }

    .header:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: url("../img/header_logo_big.svg") no-repeat center center;
    }


.header__l {
    width: 700px;
    max-width: 700px;
    position: relative;
}

    .header__l .decor_ico {
        left: -236px;
        bottom: 64px;
    }

.header__r {
    width: 380px;
    min-width: 380px;
    margin-left: 40px;
}

.header__l_info, .header__r_info {
    font-weight: 500;
    font-size: 18px;
    line-height: 132%;
    text-transform: uppercase;
    color: rgba(162, 201, 174, 0.3);
}

.header__l_info {
    margin-top: 49px;
}

.header__r_info {
    margin-bottom: 57px;
}

.header__l_img img, .header__r_img img {
    display: block;
    width: 100%;
}

.head_terraca {
    margin-top: -211px;
    position: relative;
    z-index: 30;
}

    .head_terraca .wmain {
        position: relative;
        height: 541px;
    }

    .head_terraca .wmain:before {
        content: "";
        display: block;
        position: absolute;
        width: 9000px;
        height: 100%;
        left: 800px;
        top: 0;
        /*background: rgba(162,201,174,0.6);*/
        background: #A2C9AE url("../img/header_plashka_volna.svg") no-repeat left top;
    }


.head_terraca__l, .head_terraca__r {
    position: relative;
    z-index: 20;
}

.head_terraca__l {
    position: relative;
    padding-top: 251px;
}

.head_terraca__r {
    position: absolute;
    right: 0;
    top: 67px;
    z-index: 50;
}

.tm {
    font-weight: 500;
    font-size: 104px;
    line-height: 85.5%;
    text-transform: uppercase;
    color: #FFFFFF;
    font-family: 'Pp_neue';
    position: relative;
}

    .tm span {
        display: block;
        position: relative;
        z-index: 20;
    }

    .tm i {
        font-style: normal;
        color: #A2C9AE;
    }

.tm__decor {
    position: absolute;
    left: 163px;
    bottom: -167px;
}

.tm__decor svg, .tm__decor img {
    display: block;
}

.btn_main {
    display: inline-flex;
    white-space: nowrap;
    cursor: pointer;
    border: none;
    outline-style: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    text-decoration: none;
    padding: 22px 37px 17px 25px;
    font-weight: 600;
    font-size: 22px;
    line-height: 106%;
    min-height: 96px;
    text-transform: uppercase;
    color: #FFFFFF;
    font-weight: 600;
    background: #27423E;
    border-radius: 48px;
    -webkit-align-items: center;
            align-items: center;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-transition: 0.2s;
       -moz-transition: 0.2s;
        -ms-transition: 0.2s;
         -o-transition: 0.2s;
            transition: 0.2s;
}

    .btn_main:hover {
        background: rgba(88, 136, 103, 0.8);
    }

    .btn_main svg {
        display: block;
        margin-right: 12px;
    }

    .btn_main span {
        display: block;
        padding-top: 4px;
    }

.btn_c1 {
    background: #A2C9AE;
    color: #27423E;
}

.tar {
    text-align: right;
}

    .btn_c1:hover {
        color: #FFF;
    }

.head_terraca__r .decor_ico {
    right: -234px;
    top: 22px;
}

.about {
    
}

.about .wmain {
    position: relative;
    display: flex;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

    .about .about__l:before {
        content: "";
        display: block;
        position: absolute;
        width: 9000px;
        height: 100%;
        top: 0;
        right: -340px;
        background: #A2C9AE;
    }

.about__l, .about__r {
    position: relative;
    z-index: 20;
}

.about__l {
    padding-top: 120px;
    padding-bottom: 102px;
    max-width: 460px;
}

.about__r {
    margin-top: 132px;
    width: 700px;
    min-width: 700px;
}

.about_img img {
    display: block;
    width: 100%;
}

.about_nav__info {
    font-weight: 500;
    font-size: 18px;
    line-height: 132%;
    text-align: right;
    text-transform: uppercase;
    color: rgba(162, 201, 174, 0.3);
}

.about_nav {
    display: flex;
    position: relative;
    z-index: 20;
    -webkit-align-items: center;
            align-items: center;
        -webkit-justify-content: space-between;
                justify-content: space-between;
}

    .about_nav div {
        cursor: pointer;
        font-size: 22px;
        font-weight: 600;
        color: #FFF;
        text-transform: uppercase;
        line-height: 106%;
        -webkit-transition: 0.3s;
           -moz-transition: 0.3s;
            -ms-transition: 0.3s;
             -o-transition: 0.3s;
                transition: 0.3s;
    }

        .about_nav div:hover, .about_nav div.act {
            color: #27423E;
        }


    .about_nav span {
        display: block;
        height: 61px;
        width: 1px;
        background: rgba(255, 255, 255, 0.3);
    }

.about__info {
    margin-top: 69px;
    font-size: 20px;
    line-height: 23px;
    text-align: justify;
    position: relative;
    z-index: 20;
    color: #27423E;
}

    .about__info:before {
        content: "                       ";
        white-space: pre;
    }

.hl_top {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 300;
    padding-top: 94px;
    padding-bottom: 0px;
    background: transparent;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-transition: 0.4s;
       -moz-transition: 0.4s;
        -ms-transition: 0.4s;
         -o-transition: 0.4s;
            transition: 0.4s;
}

.hl_top_page {
    padding-top: 48px;
}

.hl_top .wmain {
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.logo {
    width: 304px;
    -webkit-transition: 0.3s;
       -moz-transition: 0.3s;
        -ms-transition: 0.3s;
         -o-transition: 0.3s;
            transition: 0.3s;
}

    .hl_top_act .logo, .hl_top_act_page .logo {
        width: 193px;
    }

.logo img {
    display: block;
    width: 100%;
}

    .logo img  + img {
        display: none;
    }

.hl_top__r {
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.hl_nav {
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
}

    .hl_nav > li + li {
        margin-left: 26px;
    }

    .hl_nav > li a {
        display: block;
        text-decoration: none;
        font-size: 18px;
        line-height: 21px;
        color: #FFFFFF;
        -webkit-transition: 0.3s;
           -moz-transition: 0.3s;
            -ms-transition: 0.3s;
             -o-transition: 0.3s;
                transition: 0.3s;
    }

        .hl_nav > li a:hover, .hl_nav > li a.act {
            color: #A2C9AE;
        }

.hl_phone {
    display: flex;
    text-decoration: none;
    font-size: 18px;
    line-height: 20px;
    color: #FFFFFF;
    margin-left: 24px;
    -webkit-align-items: center;
            align-items: center;
}

    .hl_phone img {
        display: block;
        margin-right: 9px;
        position: relative;
        top: -3px;
    }

.hl_top_act, .hl_top_act_page {
    padding-top: 27px;
    padding-bottom: 28px;
    background: #27423E;
}

.hl_home {
    padding-top: 46px;
    padding-bottom: 43px;
    background: #27423E;
}

.hl_home .logo {
    width: 304px;
}

.about_bottom {
    display: flex;
    margin-top: 84px;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.popular {
    margin-top: 79px;
    position: relative;
}

    .popular .wmain {
        position: relative;
    }

.popular__content {
    margin-top: -80px;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.popular__r_3d {
    font-weight: 900;
    font-size: 255.91px;
    line-height: 85.5%;
    letter-spacing: -0.11em;
    text-transform: uppercase;
    font-family: 'Pp_neue';
    color: rgba(162, 201, 174, 0.1);
}

.popular__l {
    height: 415px;
}

.popular__l {
    width: 915px;
    min-width: 915px;
    position: relative;
    padding-right: 208px;
    margin-left: -50px;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

.popular_slide {
    height: 415px;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.popular_slide img {
    display: block;
    max-width: 100%;
    max-height: 415px;
}

.slider_nav {
    width: 183px;
    height: 52px;
    display: flex;
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.popular_slider__nav {
    position: absolute;
    right: 0;
    bottom: 70px;
}

.slider_btn {
    width: 52px;
    height: 52px;
    cursor: pointer;
}

    .slider_btn svg {
        display: block;
    }

    .slider_btn:hover svg path {
        fill: #A2C9AE;
    }

.slider_nav__num {
    position: absolute;
    left: 58px;
    right: 58px;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 132%;
    color: #FFF;
    bottom: 26px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
}

.decor_ico1 {
    left: -236px;
    bottom: 80px;
}

.decor_ico2 {
    right: -233px;
    bottom: 80px;
}

.preim {
    margin-top: 170px;
}

.tm__decor1 {
    left: auto;
    right: 129px;
    bottom: -14px;
}

.tm__decor3 {
    left: auto;
    right: 129px;
    bottom: -14px;
}

.psc__text:before {
    content: "                ";
    white-space: pre;
}

.preim__content {
    width: 1740px;
    margin: 0 auto;
    margin-top: 47px;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.preim__l {
    width: calc(100% - 720px);
}

.preim__r {
    width: 720px;
    overflow: hidden;
}

.preim__slide {
    width: 100%;
    height: 700px;
    display: flex;
    object-fit: cover;
}

    .preim__slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

.preim__slider_content {
    background: #A2C9AE;
    min-height: 430px;
    padding: 126px 240px 30px 100px;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

.psc__head {
    position: relative;
    padding-left: 80px;
}

.psc__head i {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 106%;
    text-transform: uppercase;
    color: #FFFFFF;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
}

    .psc__head span {
        display: inline-block;
        font-weight: 600;
        font-size: 22px;
        line-height: 106%;
        text-transform: uppercase;
        color: #27423E;
    }

.psc__text {
    margin-top: 50px;
    font-size: 20px;
    line-height: 23px;
    text-align: justify;
    color: #27423E;
}

.preim__r .slider_nav {
    margin-top: 79px;
    margin-left: 208px;
}

.preim__r {
    padding-top: 70px;
}

.tm__decor2 {
    left: 89px;
    bottom: -14px;
}

.preim:nth-child(2n + 1) .preim__l, .preim__content_l .preim__l {
    -webkit-order: 2;
        -ms-order: 2;
            order: 2;
}

.preim:nth-child(2n + 1) .preim__r, .preim__content_l .preim__r {
    -webkit-order: 1;
        -ms-order: 1;
            order: 1;
}

.preim:nth-child(2n + 1) .slider_nav {
    margin-left: 80px;
}

.preim__content_l .slider_nav {
    margin-left: 260px;
}

.preim:nth-child(2n + 1) .preim__slider_content, .preim__content_l .preim__slider_content {
    padding: 83px 100px 30px 240px;
}

.anim_text {
    margin-top: 229px;
}

.anim_text__inner {
    font-size: 104px;
    line-height: 85.5%;
    text-transform: uppercase;
    color: #A2C9AE;
    font-family: "Pp_neue";
    white-space: nowrap;
    position: relative;
    overflow: hidden;
}

.anim_text__inner + .anim_text__inner {
    margin-top: 7px;
}

.anim_text__inner_2 {
    color: #FFF;
}

.anim_text__inner span {
    display: block;
    position: relative;
    top: 0;
    width: 3000px;
    height: 100%;
    left: 50%;
    margin-left: -1500px;
    -webkit-animation: anim_text 26s linear infinite reverse;
       -moz-animation: anim_text 26s linear infinite reverse;
         -o-animation: anim_text 26s linear infinite reverse;
            animation: anim_text 26s linear infinite reverse;
}

.anim_text__inner img {
    display: inline-block;
    position: relative;
    top: 6px;
}

.anim_text__inner_2 span {
    -webkit-animation: anim_text 26s linear infinite;
       -moz-animation: anim_text 26s linear infinite;
         -o-animation: anim_text 26s linear infinite;
            animation: anim_text 26s linear infinite;
}

@-webkit-keyframes anim_text {
   0% {
    -webkit-transform: translateX(0);
       -moz-transform: translateX(0);
        -ms-transform: translateX(0);
         -o-transform: translateX(0);
            transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(-114%);
       -moz-transform: translateX(-114%);
        -ms-transform: translateX(-114%);
         -o-transform: translateX(-114%);
            transform: translateX(-114%);
  }
}

@keyframes anim_text {
   0% {
    -webkit-transform: translateX(0);
       -moz-transform: translateX(0);
        -ms-transform: translateX(0);
         -o-transform: translateX(0);
            transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(-114%);
       -moz-transform: translateX(-114%);
        -ms-transform: translateX(-114%);
         -o-transform: translateX(-114%);
            transform: translateX(-114%);
  }
}

.terrace {
    margin-top: 59px;
}

.terrace_slider .swiper-slide {
    width: 1100px;
    margin: 0 auto;
    position: relative;
    padding-left: 24px;
    padding-right: 24px;
    display: block !important;
}

.terrace_slide__img {
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.terrace_slide__img img {
    display: block;
    /*width: 100%;*/
    max-width: 100%;
    max-height: 570px;
}

.terrace_slide .preim__slider_content {
    width: 620px;
    min-height: 340px;
    padding: 78px 95px 30px 80px;
    margin-top: -169px;
    margin-left: 0;
    position: relative;
    z-index: 10;
    opacity: 0;
    -webkit-transform: translateX(-150%);
       -moz-transform: translateX(-150%);
        -ms-transform: translateX(-150%);
         -o-transform: translateX(-150%);
            transform: translateX(-150%);
    -webkit-transition: 0.6s;
       -moz-transition: 0.6s;
        -ms-transition: 0.6s;
         -o-transition: 0.6s;
            transition: 0.6s;
}

    .terrace_slider .swiper-slide-active .preim__slider_content {
    -webkit-transform: translate(0%);
       -moz-transform: translate(0%);
        -ms-transform: translate(0%);
         -o-transform: translate(0%);
            transform: translate(0%);
        -webkit-transition-delay: 0.3s;
           -moz-transition-delay: 0.3s;
            -ms-transition-delay: 0.3s;
             -o-transition-delay: 0.3s;
                transition-delay: 0.3s;
        opacity: 1;
        margin-left: -82px;
    }

.terrace_slider_nav {
    margin-top: -127px;
    position: relative;
    z-index: 30;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.terrace_slider_nav .btn_main {
    margin-left: 62px;
}

.inner_home {
    margin-top: 244px;
    position: relative;
    z-index: 50;
}

.tm__decor_home_inner {
    position: absolute;
    left: 100%;
    bottom: -33px;
    margin-left: -106px;
}

    .tm__decor_home_inner img {
        display: block;
    }

.inner_home .tm {
    position: relative;
    display: inline-block;
    vertical-align: top;
}

.inner_home__nav {
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.inner_home__info {
    display: flex;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    width: 782px;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

    .inner_home__info span {
        display: block;
        font-size: 18px;
        line-height: 132%;
        text-transform: uppercase;
        color: rgba(162, 201, 174, 0.3);
    }

.hod {
    margin-top: 214px;
}

.hod__nav {
    margin-top: 59px;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.hod__select {
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.hod__select .select + .select {
    margin-left: 20px;
}

.select {
    display: inline-block;
    cursor: pointer;
    z-index: 20;
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.selected_open {
    z-index: 50;
}

.selected {
    height: 59px;
    line-height: 66px;
    background: #A2C9AE;
    padding-left: 24px;
    position: relative;
    padding-right: 42px;
    font-weight: bold;
    font-size: 22px;
    text-transform: uppercase;
    color: #27423E;
    min-width: 204px;
    position: relative;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-border-radius: 30px;
            border-radius: 30px;
    -webkit-transition: 0.3s;
       -moz-transition: 0.3s;
        -ms-transition: 0.3s;
         -o-transition: 0.3s;
            transition: 0.3s;
}

    .selected:hover {
        background: rgba(88, 136, 103, 0.8);
        color: #FFF;
    }

    .selected:hover i svg path {
        fill: #fff;
    }

    .selected i {
        display: block;
        position: absolute;
        width: 18px;
        height: 11px;
        /*background: url("../img/select_str.svg") no-repeat left top;*/
        right: 18px;
        top: 50%;
        margin-top: -5px;
        -webkit-transition: 0.3s;
           -moz-transition: 0.3s;
            -ms-transition: 0.3s;
             -o-transition: 0.3s;
                transition: 0.3s;
    }

        .selected i svg {
            display: block;
        }

.selected_open .selected i {
    -webkit-transform: scale(1,-1);
       -moz-transform: scale(1,-1);
        -ms-transform: scale(1,-1);
         -o-transform: scale(1,-1);
            transform: scale(1,-1);
}

.selected_open .selected {
    background: #A2C9AE;
    color: #27423E;
}

    .selected_open .selected i svg path {
        fill: #27423E;
    }

.select__list {
    position: absolute;
    min-width: 100%;
    background: rgba(88, 136, 103, 0.8);
    top: 100%;
    opacity: 0;
    margin-top: 0px;
    padding-top: 10px;
    visibility: hidden;
    padding-bottom: 10px;
    -webkit-border-radius: 30px;
            border-radius: 30px;
    -webkit-transition: 0.3s;
       -moz-transition: 0.3s;
        -ms-transition: 0.3s;
         -o-transition: 0.3s;
            transition: 0.3s;
}

    .selected_open .selected {
        -webkit-border-bottom-left-radius: 0;
                border-bottom-left-radius: 0;
        -webkit-border-bottom-right-radius: 0;
                border-bottom-right-radius: 0;
    }

.selected_open .select__list {
    opacity: 1;
    visibility: visible;
    margin-top: -10px;
    padding-top: 0;
    background: #A2C9AE;
    -webkit-border-top-left-radius: 0;
            border-top-left-radius: 0;
    -webkit-border-top-right-radius: 0;
            border-top-right-radius: 0;
}

.select__list div {
    cursor: pointer;
    padding-left: 24px;
    padding-right: 24px;
    font-weight: 600;
    font-size: 22px;
    line-height: 132%;
    text-transform: uppercase;
    color: #27423E;
    -webkit-transition: 0.3s;
       -moz-transition: 0.3s;
        -ms-transition: 0.3s;
         -o-transition: 0.3s;
            transition: 0.3s;
}

    .select__list div:hover {
        color: #FFF;
    }

.hod__slider {
    margin-top: 77px;
    overflow: hidden;
}

.hod__slider .swiper-wrapper {
    height: auto !important;
}

.hod__slide {
    display: block;
    overflow: hidden;
    text-decoration: none;
    position: relative;
}

    .hod__slide:before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        opacity: 0;
        -webkit-transform: scale(1.2);
           -moz-transform: scale(1.2);
            -ms-transform: scale(1.2);
             -o-transform: scale(1.2);
                transform: scale(1.2);
        background: rgba(39, 66, 62, 0.6) url("../img/ico_hover_img.svg") no-repeat center center;
        -webkit-transition: 0.5s;
           -moz-transition: 0.5s;
            -ms-transition: 0.5s;
             -o-transition: 0.5s;
                transition: 0.5s;
    }

    .hod__slide img {
        display: block;
        width: 100%;
    }


.hod__slide:hover:before {
    opacity: 1;
    -webkit-transform: scale(1);
       -moz-transform: scale(1);
        -ms-transform: scale(1);
         -o-transform: scale(1);
            transform: scale(1);
}

.podbor {
    margin-top: 225px;
    overflow: hidden;
}

.podbor__head {
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.podbor__head_info {
    display: flex;
    margin-right: 69px;
    -webkit-align-items: center;
            align-items: center;
}

    .podbor__head_info img {
        display: block;
    }

    .podbor__head_info span {
        display: block;
        margin-left: 40px;
        font-weight: 600;
        font-size: 22px;
        line-height: 106%;
        text-transform: uppercase;
        color: #A2C9AE;
    }

.podbor__content {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    position: relative;
    margin-top: 47px;
    background: #A2C9AE;
    padding-top: 104px;
    padding-bottom: 98px;
}

    .podbor__content:before, .podbor__content:after {
        content: "";
        display: block;
        position: absolute;
        width: 240px;
        height: 100%;
        top: 0;
        background: #A2C9AE;
    }
    .podbor__content:before {
        left: -240px;
    }

    .podbor__content:after {
        right: -240px;
    }

.podbor__content_top {
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.pct__l {
    display: flex;
    height: 360px;
    width: 100%;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-align-items: center;
            align-items: center;
}

    .pct__l img {
        object-fit: cover;
        display: block;
        height: 100%;
        width: 100%;
    }

.pct__r {
    width: 462px;
    min-width: 462px;
    margin-left: 98px;
}

.pct__item {
    
}

    .pct__item + .pct__item {
        margin-top: 37px;
    }

.pct__item_title {
    font-weight: 600;
    font-size: 22px;
    line-height: 106%;
    text-transform: uppercase;
    color: #27423E;
}

.slid, .rc-slider-horizontal {
    position: relative;
    margin-top: 47px;
    margin-bottom: 56px;
    width: 100%;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    height: 1px;
    background: #89AE98;
}

    .slid:before, .slid:after, .rc-slider-horizontal:before, .rc-slider-horizontal:after {
        content: "";
        display: block;
        position: absolute;
        width: 13px;
        height: 13px;
        background: #89AE98;
        top: -6px;
        -webkit-border-radius: 50%;
                border-radius: 50%;
    }
    .slid:before, .rc-slider-horizontal:before {
        left: 0;
    }
    .slid:after, .rc-slider-horizontal:after {
        right: 0;
    }

.slid_circle, .rc-slider-handle {
    width: 35px;
    height: 35px;
    cursor: pointer;
    position: absolute;
    text-align: center;
    line-height: 40px;
    -webkit-align-content: center;
            align-content: center;
            font-weight: 600;
    font-size: 18px;
    color: #FFFFFF;
    margin-top: -17px;
    background: #27423E;
    z-index: 50;
    -webkit-border-radius: 50%;
            border-radius: 50%;
}

.rc-tooltip-placement-top .rc-tooltip-arrow {
    display: none !important;
}

.rc-tooltip {
    padding: 0 !important;
}

.rc-tooltip-inner {
    padding: 0 !important;
    background: none !important;
    box-shadow: none !important;
    text-align: center !important;
    width: 30px !important;
    height: auto !important;
    text-align: center !important;
    line-height: 20px !important;
    width: 35px !important;
    height: 35px !important;
    cursor: pointer !important;
    position: absolute !important;
    text-align: center !important;
    line-height: 40px !important;
    align-content: center !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    color: #FFFFFF !important;
    background: #27423E !important;
    border-radius: 50% !important;
    pointer-events: none;
    margin-top: 5px !important;
    margin-left: -17px !important;
}


.btn_select {
    display: flex;
    margin-top: 17px;
    -webkit-align-items: center;
            align-items: center;
}

    .btn_select div {
        display: flex;
        height: 59px;
        width: 100%;
        cursor: pointer;
        background: rgba(39, 66, 62, 0.2);
        -webkit-border-radius: 30px;
                border-radius: 30px;
        font-weight: 600;
        font-size: 18px;
        color: #FFFFFF;
        line-height: 100%;
        padding-top: 2px;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
        -webkit-transition: 0.3s;
           -moz-transition: 0.3s;
            -ms-transition: 0.3s;
             -o-transition: 0.3s;
                transition: 0.3s;
    }

    .btn_select div.act, .btn_select div:hover {
        background: #27423E;
    }


    .btn_select div + div {
        margin-left: 10px;
    }

    .btn_select_flat div + div {
        width: 92px;
        min-width: 92px;
    }

.podbor__content_bottom {
    margin-top: 67px;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.podbor__content_bottom .in_style + .in_style {
    margin-left: 31px;
}

.in_style {
    display: block;
    width: 100%;
}

    .in_style input, .in_style textarea {
        display: block;
        width: 100%;
        height: 72px;
        background: #27423E;
        border: none;
        padding-left: 30px;
        padding-right: 30px;
        font-weight: 600;
        font-size: 18px;
        text-transform: uppercase;
        color: #FFFFFF;
        padding-top: 3px;
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
    }

    .in_style textarea {
        height: 166px;
        padding-top: 12px;
        resize: none;
    }

.in_name {
    font-weight: 600;
    font-size: 22px;
    line-height: 106%;
    text-transform: uppercase;
    color: #27423E;
    padding-bottom: 17px;
}

.podbor__bottom {
    display: flex;
    position: relative;
    z-index: 20;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.podbor__bottom_img {
    display: block;
    max-width: 100%;
    position: relative;
    top: -49px;
}

.podbor__bottom_text {
    font-size: 18px;
    line-height: 132%;
    margin-left: 60px;
    text-transform: uppercase;
    color: rgba(162, 201, 174, 0.3);
    width: 336px;
    padding-bottom: 30px;
}

.podbor__bottom .btn_main {
    margin-left: 65px;
    margin-bottom: 30px;
}

.buy {
    margin-top: 200px;
}

.buy__slider {
    margin-top: -10px;
    overflow: hidden;
}

.buy__slide {
    cursor: pointer;
    position: relative;
    background: #A2C9AE;
    height: 290px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-transition: 0.3s;
       -moz-transition: 0.3s;
        -ms-transition: 0.3s;
         -o-transition: 0.3s;
            transition: 0.3s;
}

    .buy__slide:hover {
        background: #679575;
    }

    .buy__slide:before {
        content: "";
        display: block;
        position: absolute;
        width: 48px;
        height: 48px;
        background: url("../img/ipoteka_str.svg") no-repeat left top;
        right: 25px;
        top: 25px;
    }

.buy__slide_num {
    position: absolute;
    font-weight: 600;
    font-size: 30px;
    line-height: 106%;
    text-transform: uppercase;
    color: #FFFFFF;
    left: 38px;
    top: 33px;
}

.buy__slide_title {
    position: absolute;
    left: 38px;
    bottom: 35px;
    font-weight: 600;
    font-size: 30px;
    line-height: 106%;
    text-transform: uppercase;
    color: #27423E;
    -webkit-transition: 0.3s;
       -moz-transition: 0.3s;
        -ms-transition: 0.3s;
         -o-transition: 0.3s;
            transition: 0.3s;
}

    .buy__slide:hover .buy__slide_title {
        color: #FFF;
    }

.buy__slide1 {
    margin-top: 62px;
}

.buy__slide2 {
    margin-top: 61px;
}

.buy__slide_info {
    font-size: 18px;
    line-height: 132%;
    margin-top: 118px;
    text-transform: uppercase;
    max-width: 320px;
    padding-left: 41px;
    color: rgba(162, 201, 174, 0.3);
}

.buy__slide + .buy__slide_info {
    margin-top: 74px;
    padding-left: 0;
}

.buy__slide_info + .buy__slide {
    margin-top: 61px;
}

.buy__slide_img img {
    display: block;
    width: 100%;
}

.buy__slider .slider_nav {
    display: none;
}

.company {
    margin-top: 257px;
}

    .company .wmain {
        position: relative;
    }

    .company .tm {
        position: relative;
        z-index: 20;
    }

.company__content {
    position: relative;
    z-index: 20;
    margin-top: 64px;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.company_logo {
    position: absolute;
    left: 0;
    top: -106px;
}

.company_logo img {
    display: block;
    width: 100%;
}

.company__l {
    width: 393px;
    min-width: 393px;
    padding-top: 87px;
}

.company__r {
    width: 630px;
    padding-left: 30px;
}

.company__text_logo {
    display: flex;
    padding-bottom: 64px;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.company__text_logo img {
    display: block;
    max-width: 100%;
}

.company__text p {
    display: block;
    margin: 0;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: justify;
    color: #A2C9AE;
}

    .company__text p:before {
        content: "             ";
        white-space: pre;
    }

    .company__text p + p {
        margin-top: 28px;
    }

    .company__text p + p:before {
        display: none;
    }

.company__list {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
}

    .company__list > li + li {
        margin-top: 43px;
    }

    .company__list > li i {
        font-style: normal;
        display: block;
        font-size: 50px;
        line-height: 85.5%;
        font-family: 'Pp_neue';
        text-transform: uppercase;
        color: #FFFFFF;
    }

        .company__list > li i sup {
            font-size: 50%;
            line-height: 100%;
            position: relative;
            top: -12px;
            left: 2px;
        }

    .company__list > li span {
        display: block;
        margin-top: 10px;
        font-size: 20px;
        line-height: 23px;
        color: #A2C9AE;
    }

.contact {
    margin-top: 236px;
}

.contact .wmain {
    position: relative;
}

.contact__map {
    margin-top: 65px;
    height: 960px;
    background: #EEE;
}

.ymaps-layers-pane, .ymaps-copyrights-logo, .ymaps-controls-rightbottom {
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
    -webkit-filter: grayscale(100%);
}

.contact__plashka {
    position: absolute;
    left: -80px;
    top: 0;
    width: 540px;
    z-index: 50;
    height: 570px;
    background: #A2C9AE;
    padding: 98px 80px 0px 80px;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

.contact__list {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
    padding-left: 29px;
}

.contact__list > li {
    display: flex;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

    .contact__list > li i {
        display: flex;
        width: 41px;
        min-width: 41px;
        margin-right: 21px;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
    }

        .contact__list > li i img {
            display: block;
            max-width: 100%;
        }

    .contact__list > li span {
        display: block;
        font-weight: 600;
        font-size: 22px;
        line-height: 106%;
        text-transform: uppercase;
        color: #27423E;
        padding-top: 3px;
    }

    .contact__list > li + li {
        margin-top: 45px;
    }

    .contact__list > li a {
        display: block;
        text-decoration: none;
        color: #27423E;
        padding-top: 7px;
    }

    .contact__plashka .btn_main {
        padding-right: 0;
        width: 100%;
        margin-top: 39px;
    }

.contact__map_info {
    position: absolute;
    width: 540px;
    height: 752px;
    left: -80px;
    top: -544px;
    background: #FFF;
    padding: 78px 30px 0px 112px;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

    .contact__map_info:before {
        content: "";
        display: block;
        position: absolute;
        width: 261px;
        height: 109px;
        left: 109px;
        bottom: 48px;
        -webkit-background-size: 100%;
             -o-background-size: 100%;
                background-size: 100%;
        background: url("../img/f_logo.svg") no-repeat center bottom;
    }

.contact__map_all {
    display: inline-block;
    position: relative;
    padding-right: 90px;
    cursor: pointer;
    font-weight: 600;
    font-size: 22px;
    line-height: 106%;
    line-height: 44px;
    height: 35px;
    text-transform: uppercase;
    color: #27423E;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

    .contact__map_all span:after {
        content: "";
        display: block;
        position: absolute;
        width: 68px;
        height: 35px;
        background: #A2C9AE;
        right: 0;
        top: 0;
        -webkit-border-radius: 30px;
                border-radius: 30px;
        -webkit-transition: 0.3s;
           -moz-transition: 0.3s;
            -ms-transition: 0.3s;
             -o-transition: 0.3s;
                transition: 0.3s;
    }
    .contact__map_all input {
        display: none;
    }

    .contact__map_all span {
        display: block;
    }

    .contact__map_all span:before {
        content: "";
        display: block;
        position: absolute;
        width: 29px;
        height: 29px;
        background: #FFF;
        z-index: 10;
        right: 35px;
        top: 3px;
        -webkit-border-radius: 50%;
                border-radius: 50%;
        -webkit-transition: 0.3s;
           -moz-transition: 0.3s;
            -ms-transition: 0.3s;
             -o-transition: 0.3s;
                transition: 0.3s;
    }

    .contact__map_all input:checked + span:before {
        right: 3px;
    }

    .contact__map_all input:checked + span:after {
        background: rgba(88, 136, 103, 0.8);
    }

.cmi_list {
    margin-top: 19px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.cmi_check {
    display: block;
    cursor: pointer;
    font-size: 20px;
    line-height: 39px;
}

    .cmi_check span {
        display: block;
        position: relative;
        padding-left: 36px;
        color: #A2C9AE;
        -webkit-transition: 0.3s;
           -moz-transition: 0.3s;
            -ms-transition: 0.3s;
             -o-transition: 0.3s;
                transition: 0.3s;
    }

    .cmi_check span:before {
        content: "";
        display: block;
        position: absolute;
        width: 21px;
        height: 15px;
        left: 3px;
        top: 9px;
        opacity: 0;
        background: url("../img/check_str.svg") repeat left top;
        -webkit-transform: scale(0.2);
           -moz-transform: scale(0.2);
            -ms-transform: scale(0.2);
             -o-transform: scale(0.2);
                transform: scale(0.2);
        -webkit-transform-origin: 15px bottom;
           -moz-transform-origin: 15px bottom;
            -ms-transform-origin: 15px bottom;
             -o-transform-origin: 15px bottom;
                transform-origin: 15px bottom;
        -webkit-transition: 0.3s;
           -moz-transition: 0.3s;
            -ms-transition: 0.3s;
             -o-transition: 0.3s;
                transition: 0.3s;
    }

    .cmi_check input {
        display: none;
    }

    .cmi_check input:checked + span {
        color: #27423E;
    }

    .cmi_check input:checked + span:before {
        opacity: 1;
        -webkit-transform: scale(1);
           -moz-transform: scale(1);
            -ms-transform: scale(1);
             -o-transform: scale(1);
                transform: scale(1);
    }

.footer {
    background: #27423E;
    height: 208px;
    padding-top: 82px;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

    .footer .wmain {
        display: flex;
        -webkit-justify-content: flex-end;
                justify-content: flex-end;
    }

.f_copy {
    padding-right: 41px;
    font-size: 20px;
    line-height: 23px;
    color: #FFFFFF;
}

    .f_copy a {
        display: block;
        color: #A2C9AE;
        opacity: 0.3;
        margin-top: 12px;
    }

.home {
    position: relative;
    min-height: 100vh;
}

.home__img {
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.home__img img {
    display: block;
    width: 100%;
}

.home_one {
    position: absolute;
    height: auto;
}

    .home_one path {
        z-index: 10;
        cursor: pointer;
        height: auto;
        opacity: 0;
        -webkit-transition: 0.3s;
           -moz-transition: 0.3s;
            -ms-transition: 0.3s;
             -o-transition: 0.3s;
                transition: 0.3s;
        cursor: pointer;
        /*opacity: 1;*/
        -webkit-transform: scale(1.1);
           -moz-transform: scale(1.1);
            -ms-transform: scale(1.1);
             -o-transform: scale(1.1);
                transform: scale(1.1);
    }

    .home_one path:hover {
        opacity: 1;
        cursor: pointer;
        -webkit-transform: scale(1);
           -moz-transform: scale(1);
            -ms-transform: scale(1);
             -o-transform: scale(1);
                transform: scale(1);
    }

.home_2_1-1 {
    left: 20.2%;
    top: 41.6%;
    width: 4.381%;
    z-index: 12;
}

.home_2_1-2 {
    left: 21.37%;
    top: 46.3%;
    width: 5.881%;
    z-index: 15;
}

.home_2_2-1 {
    left: 18.57%;
    top: 35.4%;
    width: 6.281%;
    z-index: 8;
}

    .home_2_2-1 path {
        z-index: 8;
    }

.home_2_2-2 {
    left: 24.37%;
    top: 34.6%;
    width: 3.981%;
}

.home_2_2-3 {
    left: 27.67%;
    top: 34.8%;
    width: 4.681%;
}

.home_2_2-4 {
    left: 29.76%;
    top: 38%;
    width: 4.581%;
}

.home_2_2-5 {
    left: 31.46%;
    top: 41.9%;
    width: 5.36%;
}

.home_2_3-1 {
    left: 40.74%;
    top: 39.6%;
    width: 5.38%;
}

.home_2_3-2 {
    left: 43.74%;
    top: 43.6%;
    width: 6.2%;
}

.home_2_3-3 {
    left: 47.33%;
    top: 48.2%;
    width: 7.2%;
}

.home_2_4-1 {
    left: 35.93%;
    top: 31.9%;
    width: 5.5%;
}

.home_2_4-2 {
    left: 41.03%;
    top: 31.5%;
    width: 3.1%;
}

.home_2_4-3 {
    left: 43.62%;
    top: 31.8%;
    width: 4.65%;
}

.home_2_4-4 {
    left: 46.29%;
    top: 34.4%;
    width: 5.25%;
}



.home_num {
    width: 78px;
    height: 90px;
    margin-left: -39px;
    margin-top: -90px;
    background: url("../img/home_num_bg.svg") no-repeat left top;
    position: absolute;
    z-index: 5;
    pointer-events: none;
    text-align: center;
    font-size: 31px;
    line-height: 100%;
    padding-top: 28px;
    color: #FFF;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

.home_num_2-1 {left: 23.4%; top: 46.1%;}
.home_num_2-2 {left: 29.1%; top: 40%;}
.home_num_2-3 {left: 45.9%;  top: 52%;}
.home_num_2-4 {left: 42%; top: 35%;}
.home_num_2-5 {left: 60.3%; top: 48%;}
.home_num_2-6 {left: 56.5%; top: 34%;}
.home_num_2-7 { left: 65.8%; top: 33.9%;}
.home_num_2-8 {left: 72.1%; top: 47.3%;}

.home_page {
    background: #27423E !important;
    padding-top: 129px;
}

.home__info {
    position: absolute;
    background: rgba(39, 66, 62, 0.8);
    width: 100%;
    padding-top: 31px;
    padding-bottom: 30px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    left: 0;
    bottom: 0;
    z-index: 20;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

    .home__info .wmain {
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        -webkit-align-items: center;
                align-items: center;
    }

.home__info_l {
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.home__info_title {
    font-size: 40px;
    line-height: 97.5%;
    text-transform: uppercase;
    color: #FFFFFF;
}

.home__info_section {
    margin-left: 194px;
    font-size: 40px;
    line-height: 100%;
    text-transform: uppercase;
    color: #FFFFFF;
}

    .home__info_section span {
        display: block;
        font-size: 22px;
        line-height: 106%;
        text-transform: uppercase;
        color: #FFFFFF;
        margin-top: 10px;
    }

    .home__info_section span i {
        font-style: normal;
        color: #A2C9AE;
    }

.home__info_r img {
    display: block;
}

.pu_main {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 999999;
    overflow: auto;
    background: rgba(39, 66, 62, 0.8);
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

.pu_table {
    display: table;
    width: 100%;
    height: 100%;
}

.pu_cell {
    display: table-cell;
    width: 100%;
    vertical-align: middle;
    padding: 20px;
    padding-bottom: 20px;
}

.pu_inner {
    background: #A2C9AE;
    position: relative;
    max-width: 640px;
    margin: 0 auto;
}

.pu_title {
    padding-left: 30px;
    padding-right: 30px;
    background: #FFF;
    text-align: center;
    font-size: 40px;
    line-height: 100%;
    text-align: center;
    text-transform: uppercase;
    color: #27423E;
    padding-top: 51px;
    padding-bottom: 27px;
}

    .pu_title span {
        display: block;
        text-align: center;
        font-size: 24px;
        line-height: 97.5%;
        letter-spacing: -0.03em;
        color: #27423E;
        text-transform: none;
        margin-top: 15px;
        padding-bottom: 7px;
    }

.closeform {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 15px;
}

    .closeform svg {
        display: block;
    }

    .closeform:hover path {
        fill: #000;
    }

.pu_content {
    padding-top: 76px;
    padding-bottom: 94px;
    padding-left: 20px;
    padding-right: 20px;
}

.pu_content__center {
    max-width: 363px;
    margin: 0 auto;
}

.pu_inner .in_style + .in_style {
    margin-top: 29px;
}

.in_style_c1 .in_name {
    color: #FFF;
    padding-bottom: 10px;
    font-size: 16px;
}

.in_style_c1 input, .in_style_c1 textarea {
    height: 59px;
    background: #FFF;
    color: #000;
}

.in_style_c1 textarea {
    height: 166px;
}

.in_style_c1 .selected {
    background: #FFF;
    font-size: 20px;
}

.in_style .select {
    width: 100%;
    display: block;
}

    .in_style .select:hover .selected {
        color: #000;
    }

    .in_style .select:hover  i svg path {
        fill: #000;
    }

.in_style_c1 .selected_open .select__list {
    background: #FFF;
}

    .in_style_c1 .select__list div:hover {
        color: #000 !important;
    }

    .in_style_c1 .select__list div {
        font-size: 20px;
    }

.pu_btn_w {
    margin-top: 40px;
}

    .pu_btn_w .btn_main {
        width: 100%;
        -webkit-justify-content: center;
                justify-content: center;
    }

.pu_bank {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    background: #FFF;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-bottom: -20px;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}

    .pu_bank > li {
        width: 33.333333%;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 20px;
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
    }

    .pu_bank > li img {
        display: block;
        max-width: 100%;
        max-height: 60px;
    }

.nav_ico {
    display: none;
    width: 32px;
    height: 16px;
    margin-top: -5px;
    margin-left: 22px;
    cursor: pointer;
}

    .nav_ico span {
        display: block;
        width: 100%;
        height: 2px;
        background: #FFF;
        position: relative;
        top: 0;
        left: 0;
        -webkit-transform-origin: left;
           -moz-transform-origin: left;
            -ms-transform-origin: left;
             -o-transform-origin: left;
                transform-origin: left;
        -webkit-transition: 0.3s;
           -moz-transition: 0.3s;
            -ms-transition: 0.3s;
             -o-transition: 0.3s;
                transition: 0.3s;
    }

    .nav_ico span + span {
        margin-top: 5px;
    }

.nav_ico span:nth-child(2) {
    -webkit-transform-origin: center center;
       -moz-transform-origin: center center;
        -ms-transform-origin: center center;
         -o-transform-origin: center center;
            transform-origin: center center;
}

.nav_ico_act span:nth-child(2) {
    /*opacity: 0;*/
    -webkit-transform: scale(1) rotate(135deg);
       -moz-transform: scale(1) rotate(135deg);
        -ms-transform: scale(1) rotate(135deg);
         -o-transform: scale(1) rotate(135deg);
            transform: scale(1) rotate(135deg);
}

.nav_ico_act span:nth-child(1) {
    left: 5px;
    top: -4px;
    -webkit-transform: rotate(45deg);
       -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
         -o-transform: rotate(45deg);
            transform: rotate(45deg);
}

.nav_ico_act span:nth-child(3) {
    left: 5px;
    top: 4px;
    -webkit-transform: rotate(-45deg);
       -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
         -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

.pu_nav {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 250;
    padding-top: 200px;
    background: rgba(39, 66, 62, 0.9);
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

.pu_nav_list {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
}

    .pu_nav_list > li {
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
    }

    .pu_nav_list > li a {
        display: block;
        text-decoration: none;
        text-align: center;
        font-size: 28px;
        line-height: 120%;
        color: #FFF;
        -webkit-transition: 0.3s;
           -moz-transition: 0.3s;
            -ms-transition: 0.3s;
             -o-transition: 0.3s;
                transition: 0.3s;
    }

        .pu_nav_list > li a:hover {
            color: var(--c_h);
        }

        .pu_nav_list > li + li {
            margin-top: 15px;
        }

.pu_nav_btn {
    display: flex;
    margin-top: 40px;
    -webkit-justify-content: center;
            justify-content: center;
}

.tm_popular__decor_mobile {
    display: none;
    position: absolute;
    font-weight: 900;
    font-size: 28vw;
    font-family: 'Pp_neue';
    letter-spacing: -0.11em;
    text-transform: uppercase;
    color: rgba(162, 201, 174, 0.1);
    right: 6vw;
    width: 190px;
    height: 200px;
    top: 50%;
    margin-top: -100px;
    line-height: 100%;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    margin-left: -95px;
    -webkit-transform: translateY(-28%);
       -moz-transform: translateY(-28%);
        -ms-transform: translateY(-28%);
         -o-transform: translateY(-28%);
            transform: translateY(-28%);
}

    .tm_popular__decor_mobile img {
        display: block;
        max-width: 100%;
    }

.home_flat {
    background: #D6EBDC;
}

.home_flat .home__info {
    background: #27423E;
}

.falt__info {
    background: #27423E;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

    .falt__info .wmain {
        display: flex;
        height: 171px;
        position: relative;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        -webkit-align-items: center;
                align-items: center;
    }

    .falt__info .select__list {
        bottom: 100%;
        top: auto;
    }

    .falt__info .selected {
        min-width: 126px;
    }

    .falt__info .selected_open .selected {
        border-radius: 30px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    

    .falt__info .select__list {
        padding-top: 10px;
        padding-bottom: 0;
    }

    .falt__info .selected_open .select__list {
        border-radius: 30px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

.falt__info_l, .falt__info_r {
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.fil__item {
    display: flex;
    font-weight: 500;
    font-size: 18px;
    padding-top: 15px;
    text-transform: uppercase;
    color: #A2C9AE;
    line-height: 18px;
    -webkit-align-items: flex-end;
            align-items: flex-end;
}

    .fil__item span {
        font-weight: 400;
        font-size: 72px;
        line-height: 44px;
        text-transform: uppercase;
        color: #FFFFFF;
        display: block;
        margin-left: 10px;
    }

    .fil__item + .fil__item {
        margin-left: 45px;
    }

.falt__info_kompas {
    margin-left: 88px;
}

    .falt__info_kompas img {
        display: block;
        max-width: 100%;
    }

.hfc__head {
    padding-top: 38px;
}

.hfc__head .wmain {
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.hfc__head_l, .hfc__head_r {
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.hfc__head_l a {
    display: block;
    text-decoration: none;
    color: #27423E;
    transition: 0.3s;
}

.hfc__head_l a:hover {
    color: rgba(88, 136, 103, 0.8);
}

.hfc__back {
    cursor: pointer;
    display: flex;
    font-weight: 600;
    font-size: 22px;
    line-height: 100%;
    text-transform: uppercase;
    color: #27423E;
    -webkit-align-items: center;
            align-items: center;
    -webkit-transition: 0.1s;
       -moz-transition: 0.1s;
        -ms-transition: 0.1s;
         -o-transition: 0.1s;
            transition: 0.1s;
}

    .hfc__back svg {
        display: block;
        margin-right: 13px;
        margin-top: -5px;
    }

    .hfc__back span + span {
        display: none;
    }

.hfc__back:hover {
    color: rgba(88, 136, 103, 0.8);
}

    .hfc__back:hover path {
        fill: rgba(88, 136, 103, 0.8);
    }

.hfc__street {
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    text-align: justify;
    color: #27423E;
}

.home_flat__inner {
    position: relative;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    margin: 0 auto;
    margin-top: 88px;
    margin-bottom: 113px;
}

    .home_flat__inner img {
        display: block;
        max-width: 100%;
    }

.home_flat__inner_img {
    display: block;
    position: relative;
}

.home_flat__inner_img svg {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    width: 100%;
    height: auto;
}

    .home_flat__inner_img path {
        cursor: pointer;
        opacity: 0;
        -webkit-transition: 0.3s;
           -moz-transition: 0.3s;
            -ms-transition: 0.3s;
             -o-transition: 0.3s;
                transition: 0.3s;
    }

        .home_flat__inner_img path:hover {
            opacity: 0.8;
        }

.hfii_1 svg {
    top: 0.8%;
}

.hfii_2 svg {
    top: 0.2%;
}

.hfii_3 svg {
    top: 0.2%;
}

.hfii_5 {
    max-width: 960px;
}

    .hfii_5 svg {
        top: 3.4%;
        left: 2.2%;
        right: 33%;
        width: 97.7%;
    }

.hfii_6 svg {
    top: 11.8%;
    left: 0.8%;
    width: 99.2%;
}

.hfii_9 svg {
    top: 1%;
}

.hfii_22 svg {
    top: 3.4%;
    left: 3.2%;
    width: 97.2%;
}

.hfii_24 svg {
    top: 12%;
}

.home_flat__content {
    position: relative;
    z-index: 20;
}

.home_flat_flat:before {
    content: "";
    display: block;
    position: absolute;
    background: #FFFFFF;
    height: 100%;
    width: 50%;
    right: 0;
}

.falt__info {
    position: relative;
    z-index: 20;
}

.home_flat__inner_l {
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

    .home_flat__inner_l {
        width: 825px;
        min-width: 825px;
        display: flex;
        min-height: 100%;
        -webkit-align-items: center;
                align-items: center;
    }

        .home_flat__inner_l img {
            display: block;
            max-width: 100%;
        }

    .home_flat__inner_r {
        width: 100%;
        padding-left: 84px;
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
    }

.home_flat__title {
    font-weight: 500;
    font-size: 40px;
    line-height: 100%;
    text-transform: uppercase;
    color: #27423E;
    white-space: nowrap;
}

.home_flat__info {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    margin-top: 68px;
    margin-bottom: -23px;
    max-width: 320px;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}

    .home_flat__info > li {
        width: 50%;
        margin-bottom: 23px;
        width: 160px;
        font-weight: 500;
        font-size: 22px;
        line-height: 140%;
        text-transform: uppercase;
        color: #A2C9AE;
    }

        .home_flat__info > li span {
            display: block;
            font-weight: 500;
            font-size: 22px;
            line-height: 140%;
            color: #27423E;
            text-transform: none;
            margin-top: 2px;
        }

        .home_flat__info > li span sup {
            font-size: 11px;
            font-weight: bold;
            line-height: 8px;
            position: relative;
            top: -2px;
            left: 1px;
        }

.home_flat__inner_r .btn_main {
    margin-top: 87px;
}

.home_flat__inner_content {
    width: 1360px;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.lit_pt1 {
    padding-top: 8.8%;
}

.lit_pt2 {
    padding-top: 2.3%;
    padding-left: 3.2%;
    width: 97.2% !important;
}


@media screen and (max-width: 1800px) {
    .preim__content {
        width: 100%;
    }
    .inner_home__info {
        width: calc(100% - 720px);
    }
}

@media screen and (max-width: 1600px) {
    .container_main_inner .home_flat__inner {
        margin-top: 32px;
        margin-bottom: 32px;
    }
    .header:before {
        -webkit-background-size: 100%;
             -o-background-size: 100%;
                background-size: 100%;
    }
}

@media screen and (max-width: 1380px) {
    .home_flat__inner_content {
        width: calc(100vw - 60px);
    }
    .home_flat__inner_l {
        display: block;
    }
    .home_flat__inner_r {
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
    }
    .inner_home__nav {
        margin-top: 50px;
    }
    .popular__content {
        margin-top: -30px;
    }
    .preim__slider_content {
        min-height: 370px;
    }
    .preim:nth-child(2n + 1) .preim__slider_content, .preim__content_l .preim__slider_content, .preim__slider_content {
        padding: 83px 80px 30px 80px;
    }
    .preim__slide {
        height: 530px;
    }
    .preim__r {
        width: calc((100vw - 50%) - 30px);
    }

    .preim__l {
        width: calc((100vw - 50%) - 60px);
    }

    .preim:nth-child(2n) .preim__r {
        width: calc((100vw - 50%) - 30px);
    }

    .preim:nth-child(2n)  .preim__l {
        width: calc((100vw - 50%) - 60px);
    }
}

@media screen and (max-width: 1350px) {
    .home_flat__title {
        font-size: 30px;
    }
}

@media screen and (max-width: 1340px) {
    .home_flat__inner_l {
        width: 55%;
        min-width: 55%;
    }
    .home_flat_flat:before {
        width: 60%;
    }
    .contact__plashka, .contact__map_info {
        left: 0;
        width: 460px;
        padding-left: 30px;
        padding-right: 30px;
    }
    .contact__map_info:before {
        left: 30px;
    }
    .terrace_slider_nav {
        padding-right: 30px;
    }
    .wmain {
        width: 100%;
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
    }

    .head_terraca .wmain:before {
        left: auto;
        right: -8540px;
    }

    .about .about__l:before {
        left: auto;
        right: -240px;
    }
}

@media screen and (max-width: 1280px) {
    .tm__decor3 {
        right: 0;
        bottom: 0px;
    }
    .preim__content_l .slider_nav {
        margin-left: 50px;
    }
    .inner_home__info {
        margin-top: -160px;
    }
    .inner_home__info {
        width: calc(100% - 650px);
    }
    .inner_home__info span {
        font-size: 16px;
    }
    .anim_text {
        margin-top: 89px;
    }
    .anim_text__inner {
        font-size: 62px;
        line-height: 100%;
    }
    .popular__l {
        min-width: 20px;
        padding-right: 193px;
        width: calc(100vw - 370px);
    }
    .popular__r_3d {
        font-size: 210px;
    }
    .popular_slider__nav {
        bottom: 94px;
    }
    .anim_text__inner img {
        top: 4px;
        height: 52px;
    }
}

@media screen and (max-width: 1260px) {
    .podbor__bottom .btn_main {
        margin-left: 30px;
    }
    .podbor__bottom_text {
        margin-left: 30px;
    }
    .podbor__head_info span {
        font-size: 18px;
        margin-left: 20px;
    }
    .podbor__head_info {
        margin-right: 0;
    }
    .podbor__head_info img {
        width: 78px;
    }
    .hod .tm {
        font-size: 8.2vw;
    }
    .tm__decor1 {
        right: 20px;
    }
    .about__r {
        width: 50%;
        min-width: 50%;
    }

    .about_img {
        height: 500px;
        display: flex;
    }

        .about_img img {
            height: 100%;
            object-fit: cover;
        }
}

@media screen and (max-width: 1200px) {
    .home__info_title {
        font-size: 32px;
    }
    .home__info_section {
        font-size: 32px;
        margin-left: 120px;
    }
    .home__info_r {
        width: 90px;
    }
    .home__info {
        padding-top: 15px;
        padding-bottom: 12px;
    }
    .home__info_section span {
        font-size: 18px;
    }
    .tm__decor_home_inner img {
        width: 100%;
    }
    .tm__decor_home_inner {
        width: 50vw;
        margin-left: -4vw;
    }
    .tm__decor2 {
        left: 30px;
    }
    .tm__decor1 {
        right: 0;
    }
    .header__l_img {
        height: 480px;
        display: flex;
    }

        .header__l_img img {
            height: 100%;
            object-fit: cover;
        }

    .hl_top {
        padding-top: 50px;
    }
    .container_main {
        padding-top: 130px;
    }
    .header__r_info {
        margin-bottom: 27px;
    }
    .tm {
        font-size: 9.1vw;
    }

    .tm__decor img {
        width: 10vw;
    }
    .hl_nav {
        display: none;
    }
    .hl_phone {
        margin-left: 0;
    }
    .nav_ico {
        display: block;
    }
    .terrace_slide .preim__slider_content {
        margin-left: 0 !important;
    }
    .terrace_slider .swiper-slide {
        width: 100%;
        padding: 0;
    }
    .terrace_slide .preim__slider_content {
        width: 460px;
        min-height: 310px;
        padding: 58px 45px 30px 30px;
    }
}

@media screen and (max-width: 1160px) {
    .fil__item {
        font-size: 15px;
    }
    .fil__item span {
        font-size: 46px;
        line-height: 32px;
    }
    .fil__item + .fil__item {
        margin-left: 34px;
    }
    .falt__info_kompas img {
        max-width: 82px;
    }
    .falt__info_kompas {
        margin-left: 40px;
    }
    .falt__info .wmain {
        height: 111px;
    }
    .hfc__back {
        font-size: 18px;
    }
    .hfc__back svg {
        width: 25px;
        margin-right: 10px;
    }
    .hfc__street {
        font-size: 20px;
        line-height: 100%;
    }
    .home_flat__inner {
        margin-top: 50px;
        margin-bottom: 50px;
    }
}

@media screen and (max-width: 1120px) {
    .podbor__bottom_img {
        max-width: 260px;
        top: -30px;
    }
}

@media screen and (max-width: 1180px) {
    .buy__slide_title {
        font-size: 2vw;
    }
}

@media screen and (max-width: 1110px) {
    .inner_home__info {
        width: calc(100% - 547px);
    }
    .pct__r {
        margin-left: 35px;
    }
}

@media screen and (max-width: 1060px) {
    .contact .tm {
        margin-right: -30px;
    }
    .buy__slide_info {
        padding-left: 0;
    }
    .buy__slide_info {
        font-size: 1.7vw;
    }
    .podbor__head_info span {
        font-size: 16px;
        margin-left: 15px;
    }
    .podbor__head_info img {
        width: 66px;
        position: relative;
        top: -2px;
    }
    .inner_home__info {
        width: calc(100% - 514px);
        margin-top: -220px;
    }
    .terrace_slider_nav .btn_main {
        margin-left: 32px;
    }
    .preim:nth-child(2n + 1) .preim__slider_content, .preim__content_l .preim__slider_content, .preim__slider_content {
        padding: 53px 30px 30px 30px;
    }
    .preim__slide {
        height: 480px;
    }
    .preim__r {
        padding-top: 50px;
    }
    .psc__text {
        margin-top: 30px;
    }
    .popular__l {
        height: auto;
    }
    .popular_slider__nav {
        position: relative;
        top: 0;
        right: 0;
        margin: 0 auto;
        margin-top: 30px;
        bottom: 0;
    }
    .popular_slide {
        height: auto;
    }
    .popular__l {
        padding-right: 0;
        margin-left: 0;
    }
    .popular__r_3d {
        font-size: 190px;
        text-align: center;
    }
}

@media screen and (max-width: 980px) {
    .contact__plashka, .contact__map_info {
        position: relative;
        left: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
    }
    .contact .tm {
        margin-right: 0;
        text-align: center;
    }

        .contact .tm:before {
            content: "  ";
            white-space: pre;
        }
    .contact__plashka {
        margin-top: 60px;
        height: auto;
        min-height: 20px;
        display: flex;
        padding-bottom: 50px;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
    }
    .contact__plashka .btn_main {
        width: auto;
        padding-right: 30px;
    }
    .contact__map {
        margin-top: 0;
    }
    .contact__map_info {
        height: auto;
        padding-top: 38px;
        padding-bottom: 153px;
    }
    .contact__map_info:before {
        bottom: 18px;
    }
    .f_copy {
        padding-right: 0;
    }
    .footer .wmain {
        display: block;
    }
    .contact__map {
        height: 660px;
    }
    .contact {
        margin-top: 116px;
    }
    .contact__list {
        padding: 0;
        display: flex;
        margin-left: -20px;
        -webkit-align-items: center;
                align-items: center;
    }
        .contact__list > li span {
            font-size: 18px;
        }
    .contact__list > li {
        margin-left: 20px;
    }
    .contact__list > li + li {
        margin-top: 0;
    }
    .terrace_slider_nav {
        margin-top: 22px;
        -webkit-justify-content: center;
                justify-content: center;
    }
    .terrace_slider_nav .btn_main {
        margin-left: 38px;
    }

    .terrace_slide .preim__slider_content {
        width: calc(100vw - 80px);
        min-height: 233px;
        padding: 45px 33px 30px 32px;
        margin-top: -44px;
    }
    .terrace_slide .preim__slider_content {
        -webkit-transform: translateX(-50px);
           -moz-transform: translateX(-50px);
            -ms-transform: translateX(-50px);
             -o-transform: translateX(-50px);
                transform: translateX(-50px);
    }

    .terrace_slide .psc__text {
        padding-left: 80px;
    }
}

@media screen and (max-width: 960px) {
    .home_flat__inner_content {
        width: calc(100vw - 40px);
    }
    .podbor__bottom_img {
        display: none;
    }
    .podbor__bottom_text {
        margin-left: 0;
        padding-bottom: 0;
    }
    .podbor__bottom .btn_main {
        margin: 0;
        margin-left: 20px;
    }
    .podbor__bottom {
        margin-top: 30px;
    }
    .terrace_slider_nav {
        margin-top: 0;
        padding-left: 30px;
        margin-top: 30px;
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
    }
    .preim {
        margin-top: 100px;
    }
    .preim__r .slider_nav {
        margin-top: 69px;
    }

    .terrace .terrace_slider_nav {
        -webkit-justify-content: center;
                justify-content: center;
    }
}

@media screen and (max-width: 940px) {
    .hod__nav {
        display: block;
        max-width: 660px;
        margin: 0 auto;
        margin-top: 59px;
    }
    .hod .slider_nav {
        margin: 0 auto;
        margin-top: 30px;
    }
    .home_num {
        width: 50px;
        margin-left: -25px;
        font-size: 20px;
        padding-top: 19px;
        height: 58px;
        margin-top: -58px;
        -webkit-background-size: 50px;
             -o-background-size: 50px;
                background-size: 50px;
    }
    .home__info {
        padding-left: 90px;
        padding-right: 90px;
    }
    .container_main {
        padding-top: 128px;
    }
        .home__img {
            margin-left: -60px;
            margin-right: -60px;
        }
    .inner_home__info {
        display: none;
    }
    .logo {
        width: 230px;
    }
    .hl_top {
        padding-top: 45px;
    }
    .container_main {
        padding-top: 112px;
    }
    .pu_nav {
        padding-top: 140px;
    }
    .hl_top_act, .hl_top_act_page {
        padding-top: 25px;
        padding-bottom: 22px;
    }
    .head_terraca .wmain:before {
        display: none;
    }
    .header__r_img {
        display: none;
    }
    .header__r_info {
        margin-bottom: 0;
    }
    .header .wmain {
        padding-right: 0;
        position: relative;
        display: block;
        padding-top: 120px;
    }
    .container_main {
        padding-top: 162px;
    }
    .header .wmain {
        padding-top: 70px;
    }
    .header__l {
        width: 100%;
        max-width: 100%;
    }
    .header__l_img {
        height: 400px;
    }
    .head_terraca__r .decor_ico {
        display: none;
    }
    .header__r {
        width: auto;
        min-width: 20px;
        margin-left: 0;
        position: absolute;
        left: 0;
        top: 0;
    }
    .head_terraca__r {
        position: static;
    }
    .about .about__l:before {
        width: 2000px;
        left: -100px;
        top: 0;
        height: 100%;
    }
    .head_terraca__l .tm__decor {
        bottom: auto;
        left: 10vw;
        top: -59px;
    }
    .head_terraca {
        margin-top: 0;
    }
    .head_terraca__l {
        padding-top: 120px;
    }
    .header__l_info {
        display: none;
    }
    .head_terraca__r {
        display: flex;
        margin-top: 50px;
        -webkit-justify-content: center;
                justify-content: center;
    }

    .head_terraca__r .btn_main {
        color: #27423E;
        background: #A2C9AE;
    }

    .head_terraca__r .btn_main path {
        fill: #fff;
    }

    .head_terraca__r .btn_main path + path {
        fill: #27423E;
    }

    .about__l {
        padding-top: 60px;
        padding-bottom: 120px;
        max-width: 100%;
    }

    .about__r {
        position: absolute;
        margin-top: -70px;
        top: 100%;
        right: -30px;
    }

    .about_img {
        height: 250px;
    }
    .about .wmain {
        position: relative;
    }
    .about_nav__info {
        position: absolute;
        left: 0;
        text-align: left;
        top: -65px;
    }

    .home_page {
        padding-top: 128px;
    }
}

@media screen and (max-width: 920px) {
    .home_flat__title {
        white-space: normal;
    }
        .home_flat__title br {
            display: none;
        }
    .home_flat__info {
        width: auto;
    }
        .home_flat__info > li {
            width: 100%;
        }

    .home_flat__inner_content {
        -webkit-align-items: flex-start;
                align-items: flex-start;
    }
    .home_flat__inner_r {
        padding-left: 40px;
    }
    .home_flat_flat:before {
        width: 53%;
    }
    .home_flat__inner_r .btn_main {
        margin-top: 32px;
    }
    .hod__nav {
        width: 100%;
    }
    .podbor__head {
        -webkit-align-items: flex-start;
                align-items: flex-start;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
    }
        .podbor__head .tm {
            margin-top: 30px;
            -webkit-order: 2;
                -ms-order: 2;
                    order: 2;
        }

        .podbor__head .podbor__head_info {
            -webkit-order: 1;
                -ms-order: 1;
                    order: 1;
        }
}

@media screen and (max-width: 860px) {
    .home_flat__inner_l {
        width: 50%;
        min-width: 50%;
    }
}

@media screen and (max-width: 840px) {
    .fil__item {
        font-size: 13px;
    }
    .fil__item span {
        font-size: 34px;
        line-height: 28px;
    }
    .fil__item + .fil__item {
        margin-left: 24px;
    }
    .falt__info_kompas img {
        max-width: 68px;
    }
    .falt__info .wmain {
        height: 98px;
    }
    .falt__info_kompas {
        margin-left: 20px;
    }
    .contact__list {
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
        -webkit-align-items: flex-start;
                align-items: flex-start;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
    }
        .contact__list > li {
            margin-left: 0;
        }

        .contact__list > li + li {
            margin-top: 20px;
        }
    .contact__plashka {
        padding-top: 54px;
    }
    .podbor__content_top {
        display: block;
    }
    .contact__plashka .btn_main {
        margin-top: 21px;
    }
    .pct__r {
        width: 100%;
        min-width: 20px;
        margin-left: 0;
        margin-top: 40px;
    }
}

@media screen and (max-width: 820px) {
    .home_flat__title {
        font-size: 28px;
    }
    .home_flat__info > li {
        margin-bottom: 17px;
    }
        .home_flat__info {
            margin-top: 30px;
            margin-bottom: -17px;
        }
    .home_flat__inner_r {
        padding-left: 30px;
    }
    .home__info_title {
        font-size: 24px;
    }
    .home__info_section {
        font-size: 24px;
        margin-left: 60px;
    }
    .home__info_section span {
        font-size: 16px;
    }
    .company__list > li i {
        font-size: 40px;
    }
    .company__list > li span {
        font-size: 18px;
    }
    .company__l {
        width: 303px;
        min-width: 303px;
    }
    .company__text p {
        font-size: 18px;
        line-height: 22px;
    }
    .company__text p + p {
        margin-top: 18px;
    }
    .company__text_logo {
        padding-bottom: 60px;
    }
    .company_logo {
        width: 405px;
        height: 400px;
        display: flex;
        top: 0;
        -webkit-transform: translateY(-33%);
           -moz-transform: translateY(-33%);
            -ms-transform: translateY(-33%);
             -o-transform: translateY(-33%);
                transform: translateY(-33%);
        -webkit-align-items: center;
                align-items: center;
    }

    .company_logo img {
        width: 30vw;
    }
    .buy__slide + .buy__slide_info {
        margin-top: 32px;
    }
    .buy__slide_info {
        margin-top: 88px;
    }
    .buy__slide:before {
        background-position: right top;
        -webkit-background-size: 30px;
             -o-background-size: 30px;
                background-size: 30px;
    }
    .buy__slide {
        height: 220px;
    }
    .preim__r .slider_nav {
        margin-left: 30px;
        margin-top: 30px;
    }
    .preim:nth-child(2n + 1) .slider_nav {
        margin-left: 30px;
    }
}

@media screen and (max-width: 780px) {
    .tm {
        white-space: nowrap;
    }
    .tm__decor3 {
        width: 73px;
        margin-left: -36px;
        right: 30%;
        bottom: 60%;
    }
        .tm__decor3 img {
            display: block;
            width: 100%;
        }
    .hod__slider .swiper-slide {
        width: 360px;
    }

    .hod__slider {
        margin-right: -30px;
    }
    .terrace_slide .preim__slider_content {
        margin-top: -80px;
        width: calc(100vw - 100px);
    }
    .inner_home {
        margin-top: 124px;
    }
    .hod {
        margin-top: 124px;
    }
    .podbor {
        margin-top: 124px;
    }
    .buy {
        margin-top: 124px;
    }
    .company {
        margin-top: 124px;
    }
    .contact {
        margin-top: 124px;
    }
    .head_terraca .wmain {
        height: auto;
        padding-bottom: 100px;
    }
    .about__info {
        margin-top: 39px;
    }
}

@media screen and (max-width: 760px) {
    .podbor__content_bottom {
        display: block;
    }
    .podbor__content_bottom .in_style + .in_style {
        margin-left: 0;
        margin-top: 32px;
    }
}

@media screen and (max-width: 740px) {
    .falt__info .wmain {
        flex-direction: column;
        height: auto;
        padding-bottom: 15px;
        padding-top: 15px;
    }
    .falt__info_r {
        justify-content: flex-end;
    }
    .falt__info_l, .falt__info_r {
        width: 100%;
    }

    .falt__info_l {
        order: 2;
        margin-top: 20px;
    }

    .falt__info_r {
        order: 1;
    }
}

@media screen and (max-width: 720px) {
    .hod__select {
        display: block;
        max-width: 400px;
        margin: 0 auto;
    }
    .hod__select .select {
        margin-left: 0 !important;
        width: 100% !important;
    }

    .hod__select .select + .select {
        margin-top: 20px;
    }
    .container_main_inner {
        overflow: visible;
    }
    .hfc__head {
        position: sticky;
        left: 0;
        top: 70px;
        padding-left: 30px;
        padding-right: 30px;
        background: #FFF;
        margin-left: -30px;
        margin-right: -30px;
        padding-bottom: 30px;
        border-bottom: 1px solid #eee;
        z-index: 100;
    }
    .home_flat__inner_content {
        display: block;
        width: 100%;
    }
    .home_flat__inner_l {
        width: 100%;
        min-width: 20px;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
    }
    .home_flat__inner_r {
        padding-left: 0;
        margin-top: 40px;
    }
    .home_flat__info > li {
        width: 160px;
    }
    .home_flat__inner_r {
        background: #FFFFFF;
        display: block;
        width: auto;
        padding-left: 30px;
        padding-right: 30px;
        margin-left: -30px;
        margin-right: -30px;
        padding-top: 60px;
        padding-bottom: 60px;
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
    }
    .home_flat_flat:before {
        display: none;
    }
    .home_flat__title {
        font-size: 32px;
    }
    .tm__decor3 {
        width: 73px;
        right: 20%;
        margin-left: -36px;
    }
    .podbor__bottom {
        margin-top: 33px;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
    }
    .podbor__bottom_text {
        margin-top: 25px;
        -webkit-order: 2;
            -ms-order: 2;
                order: 2;
    }
    .podbor__bottom .btn_main {
        margin-left: 0;
        -webkit-order: 1;
            -ms-order: 1;
                order: 1;
    }
    .hod__nav {
        display: block;
    }

    .hod__nav .slider_nav {
        margin-top: 30px;
    }
    .hod__select .select {
        width: calc(50% - 10px);
    }
}

@media screen and (max-width: 680px) {
    .company__content {
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
    }
    .company__l {
        padding-top: 0;
        width: auto;
        max-width: 100%;
        text-align: center;
        min-width: 20px;
        margin-top: 60px;
        -webkit-order: 2;
            -ms-order: 2;
                order: 2;
    }

    .company__r {
        padding: 0;
        width: auto;
        -webkit-order: 1;
            -ms-order: 1;
                order: 1;
    }

    .preim__content {
        display: block;
    }
    .preim__l {
        width: auto;
        margin-left: -30px;
        margin-right: -30px;
    }
    .preim__r {
        padding-top: 0;
        width: auto;
        margin-left: -30px;
        margin-right: -30px;
    }
    .preim__r .preim__slider_content {
        min-height: 20px;
        padding: 35px 35px 33px 20px !important;
    }
    .preim__r .slider_nav {
        margin: 0 auto !important;
        margin-top: 28px !important;
    }

    .tm__decor2 {
        left: 10%;
        width: 73px;
        margin-left: -36px;
        bottom: 60%;
    }

    .preim {
        margin-top: 102px;
    }

    .preim .tm {
        text-align: left;
        font-size: 10.8vw;
        line-height: 100%;
    }

        .preim .tm i {
            display: block;
            text-align: right;
        }

        .preim .tm br {
            display: none;
        }

    .tm__decor2 img {
        display: block;
        width: 100%;
    }

    .preim__l, .preim__r {
        min-width: 20px !important;
        width: auto !important;
    }

    .preim .tm2, .preim .tm2 span, .preim .tm2 span i {
        text-align: left;
    }

    .preim .tm2 {
        margin-left: -20px;
        margin-right: -20px;
    }
    .tm__decor1 {
        bottom: 53%;
        width: 65px;
        right: 15%;
    }
        .tm__decor img {
            width: 100%;
        }
}

@media screen and (max-width: 660px) {
    .hfc__head {
        padding-left: 20px;
        padding-right: 20px;
        margin-left: -20px;
        margin-right: -20px;
    }
    .home_flat__inner_r {
        padding-left: 20px;
        padding-right: 20px;
        margin-left: -20px;
        margin-right: -20px;
    }
    .plr {
        padding-left: 20px;
        padding-right: 20px;
    }
    .buy__slider {
        margin-left: -20px;
        margin-right: -20px;
        padding-left: 20px;
        padding-right: 20px;
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
    }
    .buy__slide_img {
        display: none;
    }
    .buy__slider .slider_nav {
        display: flex;
        margin: 0 auto;
        margin-top: 30px;
    }
    .buy__slider .swiper-slide {
        display: flex;
        width: calc(80vw);
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
    }

    .buy__slide2_main .buy__slide_info {
        margin-top: 0;
        -webkit-order: 2;
            -ms-order: 2;
                order: 2;
    }

    .buy__slide2_main .buy__slide {
        margin-top: 0;
        -webkit-order: 1;
            -ms-order: 1;
                order: 1;
    }
    .buy__slide {
        margin-top: 0;
    }
    .buy__slider {
        margin-top: 30px;
    }
    .buy__slide_info {
        margin-top: 30px !important;
    }
    .buy__slide_title {
        font-size: 24px;
    }
    .popular__content {
        display: block;
        margin-top: 7px;
    }
    .popular__l {
        width: 100%;
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
    }

    .popular_slider {
        padding-left: 20px;
        padding-right: 20px;
        margin-left: -20px;
        margin-right: -20px;
    }
    .popular {
        margin-top: 127px;
    }
    .popular__r_3d {
        display: none;
    }

    .popular .tm {
        position: relative;
    }

    .tm_popular_inner {
        position: relative;
        z-index: 20;
    }

    .tm_popular__decor_mobile {
        display: flex;
    }

    .tm_popular_inner {
        white-space: nowrap;
        text-align: left;
    }

        .tm_popular_inner span:before {
            content: "      ";
            white-space: pre;
        }
    .popular__r {
        display: flex;
        margin-top: 36px;
        -webkit-justify-content: center;
                justify-content: center;
    }
    .popular_slider__nav {
        margin-top: 0;
    }
    .preim__l, .preim__r {
        margin-left: -20px !important;
        margin-right: -20px !important;
    }
    .home__info {
        padding-left: 80px;
        padding-right: 80px;
    }
}

@media screen and (max-width: 640px) {
    .hfc__back {
        font-size: 15px;
    }
    .hfc__back svg {
        width: 19px;
    }
    .hfc__street {
        font-size: 18px;
    }
}

@media screen and (max-width: 620px) {
    .pu_nav {
        overflow: auto;
        padding-bottom: 30px;
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
    }
    .home__info .wmain {
        display: block;
    }
    .home__info_l {
        width: 100%;
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }
    .home__info {
        padding-top: 25px;
        padding-bottom: 20px;
        -webkit-transform: translateY(100%);
           -moz-transform: translateY(100%);
            -ms-transform: translateY(100%);
             -o-transform: translateY(100%);
                transform: translateY(100%);
    }
    .home {
        padding-bottom: 190px;
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
    }
}

@media screen and (max-width: 580px) {
    .home_page {
        padding-top: 112px;
    }
    .home__info {
        -webkit-transform: translateY(100%);
           -moz-transform: translateY(100%);
            -ms-transform: translateY(100%);
             -o-transform: translateY(100%);
                transform: translateY(100%);
    }
    .terrace_slide .preim__slider_content {
        margin-top: -44px;
    }
    .terrace_slide__img {
        display: flex;
        height: 230px;
    }

        .terrace_slide__img img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
}

@media screen and (max-width: 540px) {
    .company_logo {
        width: auto;
        height: auto;
        left: auto;
        right: 0;
        -webkit-transform: translateY(-80%);
           -moz-transform: translateY(-80%);
            -ms-transform: translateY(-80%);
             -o-transform: translateY(-80%);
                transform: translateY(-80%);
    }
    .company .tm {
        text-align: left;
    }
    .logo {
        width: 106px !important;
    }
    .logo img {
        display: none;
    }

    .logo img + img {
        display: block;
    }
    .hl_top {
        padding-top: 18px !important;
        padding-bottom: 18px !important;
    }
    .container_main {
        padding-top: 102px;
    }
    .header__r_info {
        font-size: 12px;
        line-height: 132%;
    }
    .header .wmain {
        padding-top: 45px;
    }
    .home_page {
        padding-top: 82px;
    }
    .home_num {
        width: 30px;
        margin-left: -15px;
        height: 33px;
        margin-top: -33px;
        font-size: 14px;
        padding-top: 10px;
        -webkit-background-size: 30px;
             -o-background-size: 30px;
                background-size: 30px;
    }
}

@media screen and (max-width: 520px) {
    .tm__decor1 {
        right: 20%;
        width: 65px;
        bottom: 60%;
    }

        .tm__decor1 img {
            display: block;
            width: 100%;
        }
}

@media screen and (max-width: 480px) {
    .falt__info .selected_open .select__list {
        border-radius: 16px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .falt__info .selected_open .selected {
        border-radius: 16px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .fil__item {
        width: 33.3333%;
        box-sizing: border-box;
        padding-left: 20px;
        display: block;
    }

    .falt__info_l {
        width: 100%;
        margin-left: -20px;
    }

    .fil__item + .fil__item {
        margin-left: 0;
    }

    .falt__info_l .select {
        display: block;
        width: 100%;
    }

    .falt__info_r .fil__item {
        display: flex;
        width: auto;
        padding-left: 0;
    }

    .falt__info_l .fil__item {
        padding-top: 0;
    }
    

    .falt__info .selected {
        min-width: 100%;
        width: 100%;
    }
    .hfc__head {
        top: 60px;
    }
    .home_flat__inner_r {
        padding-left: 20px;
        padding-right: 20px;
        margin-left: -20px;
        margin-right: -20px;
    }
    .hfc__head {
        padding-top: 25px;
        padding-bottom: 25px;
    }
    .hfc__back {
        padding: 20px;
        padding-left: 0;
        font-size: 14px;
        padding-right: 0;
        margin-left: -10px;
        position: relative;
        top: 2px;
    }
        .hfc__back span {
            display: none;
        }

        .hfc__back span + span {
            display: block;
        }
    .hfc__back svg {
        position: relative;
        top: 1px;
    }
    .hfc__head {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .home_flat__inner {
        margin-top: 30px;
        margin-bottom: 30px;
        overflow: auto;
        display: block;
        margin-left: -10px;
        width: calc(100vw - 20px);
    }
    .home_flat__inner_img {
        width: 600px;
        min-width: 600px;
    }
    .home__info_title {
        font-size: 20px;
    }
        .home__info_title br {
            display: none;
        }
    .home__info_l {
        display: block;
    }
    .home__info_section {
        margin-left: 0;
        margin-top: 10px;
    }
    .home__info_section {
        font-size: 20px;
        margin-top: 20px;
    }
    .home__info_r {
        width: 60px;
        margin-top: 20px;
    }
    .home {
        padding-bottom: 0;
    }
    .home__info {
        display: none;
    }
    .home__info_section span {
        font-size: 14px;
        margin-top: 5px;
    }
    .in_style textarea {
        padding-left: 20px;
        padding-right: 20px;
        font-size: 12px;
        padding-top: 10px;
        height: 126px;
    }
    .pu_title span {
        font-size: 20px;
        max-width: 300px;
        margin: 0 auto;
        margin-top: 12px;
    }
    .pu_title {
        font-size: 30px;
    }
    .pu_content {
        padding-top: 38px;
        padding-bottom: 45px;
    }
    .pu_btn_w {
        margin-top: 20px;
    }
    .pu_cell {
        padding-left: 0;
        padding-right: 0;
    }
    .pu_inner .selected {
        height: 50px;
        line-height: 56px;
        -webkit-border-radius: 25px;
                border-radius: 25px;
    }
        .pu_inner .selected_open .selected {
            -webkit-border-bottom-left-radius: 0px;
                    border-bottom-left-radius: 0px;
            -webkit-border-bottom-right-radius: 0px;
                    border-bottom-right-radius: 0px;
        }
    .inner_home {
        position: relative;
    }
        .inner_home:before {
            content: "";
            display: block;
            position: absolute;
            width: 100vw;
            height: 713px;
            right: -20px;
            top: 100%;
            margin-top: -145px;
            pointer-events: none;
            background: url("../img/bg_mobile3.svg") no-repeat right top;
        }
    .psc__text {
        font-size: 14px;
        line-height: 16px;
        margin-top: 18px;
    }
    .preim__slide {
        height: 247px;
    }
    .inner_home {
        margin-top: 26px;
    }
    .hod {
        position: relative;
        z-index: 20;
    }
    .hod__slider .swiper-slide {
        width: 216px;
    }
    .hod__slider {
        margin-left: -20px;
        margin-right: -20px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .podbor__head_info span {
        font-size: 12px;
        line-height: 106%;
        margin-left: 14px;
    }
    .podbor__head_info img {
        width: 49px;
    }
    .selected {
        height: 32px;
        font-size: 12px;
        padding-left: 14px;
        padding-right: 23px;
        line-height: 36px;
        -webkit-border-radius: 16px;
                border-radius: 16px;
    }
    .select__list {
        -webkit-border-radius: 16px;
                border-radius: 16px;
    }
    .selected i {
        width: 12px;
        right: 11px;
    }
    .hod__select .select {
        width: 88px;
    }
    .hod__select .select + .select {
        width: 110px;
        margin-left: 11px;
    }
    .selected {
        min-width: 20px;
    }
    .hod__select {
        -webkit-justify-content: center;
                justify-content: center;
    }
    .selected i svg {
        width: 100%;
    }
    .select__list div {
        padding-left: 14px;
        font-size: 12px;
        padding-right: 14px;
    }
    .selected_open .select__list {
        margin-top: 0;
    }
    .contact {
        margin-top: 54px;
    }
    .contact__plashka {
        margin-top: 15px;
    }
    .container_main {
        background: #27423E url(../img/header_bg_mobile.svg) no-repeat right top;
    }
    .contact__plashka {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 34px;
        padding-bottom: 37px;
    }
    .contact__plashka .btn_main {
        margin-top: 27px;
    }
    .contact__list > li i {
        width: 29px;
        min-width: 29px;
        margin-right: 15px;
    }
        .contact__list > li i img {
            max-height: 34px;
        }
    .contact__list > li span {
        font-size: 16px;
        line-height: 106%;
    }
    .contact__map {
        height: 367px;
    }
    .head_terraca__l .tm__decor {
        width: 47px;
        margin-left: -24px;
        left: 25%;
        top: -60%;
    }
    .header__l_img {
        margin-left: -20px;
        margin-right: -20px;
        height: 282px;
    }
    .tm {
        white-space: nowrap;
    }
    .head_terraca__l {
        padding-top: 87px;
    }
    .head_terraca__r {
        margin-top: 22px;
    }
    .head_terraca .wmain {
        padding-bottom: 43px;
    }
    .btn_main {
        font-size: 14px;
        line-height: 106%;
        min-height: 60px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 16px;
        padding-right: 23px;
    }
    .btn_main svg {
        width: 31px;
        height: 31px;
        margin-right: 8px;
    }

    .slider_nav {
        width: 99px;
        height: 30px;
    }
    .slider_btn {
        width: 30px;
        height: 30px;
    }

        .slider_btn svg {
            display: block;
            width: 30px;
            height: 30px;
        }
    .slider_nav__num {
        font-size: 10px;
        left: 34px;
        right: 34px;
        bottom: 14px;
        border-width: 1px;
    }

    .pu_nav {
        padding-top: 114px;
    }
    .pu_nav_btn {
        margin-top: 37px;
    }
    .pu_nav_list > li a {
        font-size: 16px;
        line-height: 21px;
    }

    .pu_nav_list > li + li {
        margin-top: 12px;
    }
    .hl_phone {
        font-size: 14px;
        line-height: 16px;
    }
        .hl_phone img {
            width: 15px;
        }
    .hl_top_act .logo, .hl_top_act_page .logo {
        width: 60px !important;
    }

    .contact__bottom {
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
    }
    .contact__map_info {
        width: auto;
    }

    .contact__map_info {
        padding-left: 53px;
        padding-bottom: 137px;
    }
    .contact__map_all {
        font-size: 16px;
        line-height: 34px;
        height: 26px;
        padding-right: 68px;
    }
    .cmi_list {
        margin-top: 14px;
    }
    .cmi_check span {
        font-size: 15px;
        line-height: 29px;
    }
    .cmi_check span {
        padding-left: 25px;
    }
    .cmi_check span:before {
        width: 16px;
        height: 12px;
        background-position: left center;
        -webkit-background-size: 16px;
             -o-background-size: 16px;
                background-size: 16px;
    }
    .cmi_check span:before {
        left: 1px;
        top: 7px;
    }
    .contact__map_all span:after {
        width: 51px;
        height: 26px;
    }
    .contact__map_all span:before {
        width: 21px;
        height: 21px;
        left: auto;
        right: 27px;
        top: 2px;
    }
    .contact__map_info:before {
        left: 53px;
        bottom: 20px;
        background-position: left bottom;
        -webkit-background-size: 175px;
             -o-background-size: 175px;
                background-size: 175px;
    }
    .podbor__content {
        padding-top: 22px;
        padding-bottom: 29px;
    }
    .pct__l {
        height: 164px;
    }
    .pct__r {
        margin-top: 27px;
    }
    .pct__item_title {
        font-size: 15px;
        line-height: 106%;
    }
    .slid:before, .slid:after {
        width: 9px;
        height: 9px;
        top: -4px;
    }
    .slid_circle {
        width: 24px;
        line-height: 28px;
        height: 24px;
        margin-top: -12px;
        font-size: 12px;
    }
    .slid {
        margin-top: 32px;
        margin-bottom: 38px;
    }
    .btn_select div {
        height: 40px;
        font-size: 12px;
    }
    .in_name {
        padding-bottom: 11px;
        font-size: 15px;
        line-height: 106%;
    }
    .in_style input {
        height: 50px;
        font-size: 12px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .podbor__content_bottom .in_style + .in_style {
        margin-top: 19px;
    }
    .podbor__content_bottom {
        margin-top: 27px;
    }
    .podbor {
        margin-top: 71px;
    }
    .hod .wmain {
        position: relative;
        padding-bottom: 57px;
    }
    .hod__nav .slider_nav {
        position: absolute;
        left: 50%;
        bottom: 0;
        -webkit-transform: translateX(-50%);
           -moz-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
             -o-transform: translateX(-50%);
                transform: translateX(-50%);
    }
    .hod__slider {
        margin-top: 24px;
    }
    .hod__nav {
        margin-top: 18px;
    }
    .hod {
        margin-top: 85px;
    }
    .inner_home__nav {
        margin-top: 27px;
    }
    .home_flat__inner2 {
        width: auto;
        min-width: 2px;
        overflow: visible;
    }
    .home_flat__inner2 {
        margin-bottom: 0;
    }
    .home_flat__inner_r {
        margin-top: 30px;
    }
}

@media screen and (max-width: 440px) {
    .btn_select_flat div + div {
        width: 60px;
        min-width: 60px;
    }
    .buy {
        margin-top: 60px;
    }
    .tm_popular__decor_mobile {
        right: 0;
        -webkit-transform: translateY(-22%);
           -moz-transform: translateY(-22%);
            -ms-transform: translateY(-22%);
             -o-transform: translateY(-22%);
                transform: translateY(-22%);
        -webkit-justify-content: flex-end;
                justify-content: flex-end;
    }
}

@media screen and (max-width: 420px) {
    .hfc__head {
        padding-top: 5px;
        padding-bottom: 0;
    }
    .home_flat__title {
        font-size: 28px;
    }
    .btn_select {
        margin-top: 12px;
    }
    .pct__item + .pct__item {
        margin-top: 25px;
    }
    .podbor__content {
        margin-top: 15px;
    }
    .in_style_c1 .selected {
        font-size: 15px;
    }
    .footer {
        height: auto;
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .f_copy {
        font-size: 12px;
        line-height: 14px;
    }
    .podbor__bottom_text {
        font-size: 12px;
        line-height: 132%;
        width: 240px;
        position: relative;
        z-index: 20;
    }
    .podbor__bottom .btn_main {
        position: relative;
        z-index: 20;
    }
    .podbor__bottom {
        position: relative;
    }

        .podbor__bottom:before {
            content: "";
            width: 100vw;
            left: -20px;
            display: block;
            position: absolute;
            top: -33px;
            height: 563px;
            background: url("../img/bg_mobile4.svg") no-repeat left top;
            pointer-events: none;
        }
    .buy__slider {
        position: relative;
        z-index: 20;
    }
    .company__content {
        margin-top: 23px;
    }
    .company__text_logo img {
        max-width: 154px;
        margin: 0 auto;
    }
    .company__text_logo {
        padding-bottom: 23px;
    }
    .company__text p {
        font-size: 12px;
        line-height: 14px;
    }
    .company__text p + p {
        margin-top: 14px;
    }
    .company__l {
        margin-top: 38px;
    }
    .company__list > li i {
        font-size: 31px;
        line-height: 85.5%;
    }
    .company__list > li span {
        margin-top: 5px;
        font-size: 12px;
        line-height: 14px;
    }
    .company__list > li + li {
        margin-top: 24px;
    }
    .psc__head i {
        font-size: 16px;
        line-height: 100%;
    }
    .psc__head span {
        font-size: 16px;
        line-height: 126%;
        position: relative;
        top: -4px;
        display: block;
    }
    .psc__head {
        padding-left: 42px;
    }
    .terrace_slide .psc__text {
        padding-left: 42px;
        margin-top: 28px;
        font-size: 14px;
        line-height: 16px;
    }
    .terrace {
        margin-top: 18px;
    }
    .anim_text {
        margin-top: 63px;
    }
    .anim_text__inner {
        font-size: 41px;
        line-height: 85.5%;
    }
        .anim_text__inner img {
            top: 1px;
            height: 32px;
        }
    .anim_text__inner span {
        -webkit-animation: anim_text 76s linear infinite;
        -moz-animation: anim_text 76s linear infinite;
        -o-animation: anim_text 76s linear infinite;
        animation: anim_text 76s linear infinite;
    }

        .anim_text__inner + .anim_text__inner  span {
        -webkit-animation: anim_text 76s linear infinite reverse;
        -moz-animation: anim_text 76s linear infinite reverse;
        -o-animation: anim_text 76s linear infinite reverse;
        animation: anim_text 76s linear infinite reverse;
    }
    .preim__content {
        margin-top: 14px;
    }
    .tm__decor1 {
        right: 15px;
    }
    .about_nav span {
        height: 36px;
    }
    .about_nav div {
        font-size: 16px;
    }
    .about__l {
        padding-top: 24px;
        padding-bottom: 82px;
    }
    .about__r {
        margin-top: -47px;
    }
    .about__info {
        margin-top: 15px;
        font-size: 14px;
        line-height: 16px;
    }
    .about_img {
        height: 208px;
    }
    .about__r {
        width: calc(100vw - 75px);
        right: -20px;
    }
    .about_bottom {
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        margin-top: 198px;
    }
    .about_nav__info {
        position: relative;
        z-index: 20;
        left: 0;
        top: 0;
        padding-top: 17px;
        font-size: 12px;
        line-height: 132%;
        -webkit-order: 1;
            -ms-order: 1;
                order: 1;
    }
        .about_bottom .btn_main {
            position: relative;
            z-index: 20;
            -webkit-order: 2;
                -ms-order: 2;
                    order: 2;
        }
    .buy__slide_info {
        margin-top: 86px !important;
    }
    .buy__slide {
        height: 170px;
    }
    .buy__slide_title {
        font-size: 18px;
        left: 22px;
        bottom: 20px;
    }
    .buy__slide_num {
        font-size: 18px;
        left: 22px;
        top: 20px;
    }
    .buy__slide:before {
        right: 15px;
        top: 15px;
        -webkit-background-size: 28px;
             -o-background-size: 28px;
                background-size: 28px;
    }

    .buy__slider .slider_nav {
        position: absolute;
        top: 200px;
        left: 50%;
        margin-top: 0;
        z-index: 40;
        -webkit-transform: translateX(-50%);
           -moz-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
             -o-transform: translateX(-50%);
                transform: translateX(-50%);
    }
    .buy__slide_info {
        font-size: 12px;
        max-width: 210px;
        line-height: 132%;
        padding-left: 49px !important;
    }
    .about {
        position: relative;
    }
        .about .wmain {
            position: relative;
            z-index: 20;
        }

    .about_bottom:before {
        content: "";
        display: block;
        position: absolute;
        width: 100vw;
        height: 377px;
        left: -20px;
        top: -198px;
        background: url("../img/bg_mobile2.svg") repeat left top;
    }

    .terrace .preim__slider_content {
        width: calc(100vw - 42px);
    }
    .tm__decor_home_inner {
        position: static;
        width: auto;
        margin: 0;
        margin-left: -10px;
        margin-right: -10px;
        padding-bottom: 16px;
    }
}

@media screen and (max-width: 360px) {
    .hfc__street {
        font-size: 16px;
    }
    .home_flat__title {
        font-size: 26px;
    }
    .home_flat__info {
        margin-bottom: -11px;
    }
    .home_flat__inner_r .btn_main {
        margin-top: 18px;
    }
    .home_flat__inner_r {
        padding-top: 32px;
        padding-bottom: 32px;
    }
    .home_flat__info {
        margin-top: 25px;
    }
    .home_flat__info > li {
        font-size: 18px;
        margin-bottom: 11px;
    }
        .home_flat__info > li span {
            font-size: 18px;
        }
    .home_flat__info {
        width: 100%;
    }
        .home_flat__info > li {
            width: 50%;
            white-space: nowrap;
        }
    .fil__item {
        font-size: 12px;
        padding-top: 8px;
    }
    .fil__item span {
        font-size: 20px;
        line-height: 20px;
        margin-left: 6px;
    }
    .falt__info_kompas img {
        max-width: 56px;
    }
    .falt__info_r {
        margin-top: 14px;
    }
    .falt__info .wmain {
        padding-bottom: 15px;
    }
    .btn_select_flat div + div {
        width: 50px;
        min-width: 50px;
    }
    .logo {
        width: 76px !important;
    }
    .home_page {
        padding-top: 68px;
    }
    .pu_title {
        font-size: 24px;
        padding-top: 41px;
        padding-bottom: 19px;
    }
    .pu_title span  {
        font-size: 16px;
        margin-top: 7px;
        padding-bottom: 5px;
    }
}