@charset "UTF-8";
/* Slider */
.slick-slider { position: relative; display: block; box-sizing: border-box; -moz-box-sizing: border-box; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; -ms-touch-action: pan-y; touch-action: pan-y; -webkit-tap-highlight-color: transparent; }

.slick-list { position: relative; overflow: hidden; display: block; margin: 0; padding: 0; }
.slick-list:focus { outline: none; }
/*.slick-loading .slick-list { background: #fff url("./ajax-loader.gif") center center no-repeat; }*/
.slick-list.dragging { cursor: pointer; cursor: hand; }

.slick-slider .slick-track { -webkit-transform: translate3d(0, 0, 0); -moz-transform: translate3d(0, 0, 0); -ms-transform: translate3d(0, 0, 0); -o-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }

.slick-track { position: relative; left: 0; top: 0; display: block; }
.slick-track:before, .slick-track:after { content: ""; display: table; }
.slick-track:after { clear: both; }
.slick-loading .slick-track { visibility: hidden; }

.slick-slide { float: left; height: 100%; min-height: 1px; display: none; outline: none !important; }
[dir="rtl"] .slick-slide { float: right; }
.slick-slide img { display: block; }
.slick-slide.slick-loading img { display: none; }
.slick-slide.dragging img { pointer-events: none; }
.slick-initialized .slick-slide { display: block; }
.slick-loading .slick-slide { visibility: hidden; }
.slick-vertical .slick-slide { display: block; height: auto; border: 1px solid transparent; }

.slick-prev, .slick-next {
	position: absolute;
	width: 30px;
	height: 30px;
	background: #000;
	top: 50%;
	margin-top: -15px;
	overflow: hidden;
	border: none;
	display: block;
	text-indent: -99999999999px;
	z-index: 50;
	outline-style: none;
	cursor: pointer;
	font-size: 0;
	line-height: 0;
}

	.slick-prev {
		left: 0;
		background-position: left top;
	}

	.slick-next {
		right: 0;
		background-position: right top;
	}

/* Dots */
.slick-dots {
	position: absolute;
	bottom: 0;
	list-style: none;
	display: block;
	text-align: center;
	padding: 0;
	width: 100%;
	margin: 0;
	padding: 0;
	bottom: 60px;
}

.slick-dots li {
	display: inline-block;
	vertical-align: top;
	margin: 0;
	margin-left: 8px;
	margin-right: 8px;
}
.slick-dots li button {
	display: block;
	position: relative;
	font-size: 0;
	line-height: 0;
	background: #000;
	width: 16px;
	height: 5px;
	border: none;
}

	.slick-dots .slick-active button, .slick-dots li button:hover {
		background: red;
	}

/*# sourceMappingURL=slick.css.map */
